import { getAllData } from "./fetching";
import { LIST_DIGITAL_LAB_INSTRUMENT_REPOSITORY_USERS, LIST_IR_GROUP_ADMIN_MAPPINGS } from "../../gql/landingapi";
import { sortBy } from "lodash";
import { extractReviewersIds } from "./dataMappers";

/**
 * List of sites that we apply 4EP for.
 * @type {string[]}
 */
const ACCEPTABLE_SITES = ["Ludwigsburg", "Mannheim", "Penzberg"];

/**
 * Helper function to check if equipment data fulfills conditions for 4EP for new equipments.
 * @param belongingToGroup
 * @param siteName
 * @return {*|boolean}
 */
export function isNewData4eye({ belongingToGroup, siteName }) {
  return belongingToGroup?.startsWith("DSR") && ACCEPTABLE_SITES.includes(siteName);
}

/**
 * Helper function to check if equipment data fulfills conditions for 4EP for existing equipments
 * @param belongingToGroup
 * @param siteName
 * @param gxpRelevant
 * @return {*|boolean|boolean}
 */
export function isData4eye({ belongingToGroup, siteName, gxpRelevant }) {
  return (
    belongingToGroup?.startsWith("DSR") &&
    ACCEPTABLE_SITES.includes(siteName) &&
    (gxpRelevant?.value ?? gxpRelevant)?.toLowerCase?.() === "yes"
  );
}

/**
 * Helper function to check if equipment id is specific to newly created, not-yet approved (fake) equipments.
 * @param id
 * @return {*}
 */
export function isId4eye(id) {
  return id?.startsWith?.("4epf_");
}

/**
 * Helper function to prepare a list of reviewers for all 4EP processes like adding, editing and removing equipments.
 * @param client
 * @param equipmentGroup
 * @param currentUser
 * @return {Promise<{}[]>}
 */
export async function getReviewers(client, equipmentGroup, currentUser) {
  const [{ items: userItems }, ...groupsResults] = await Promise.all([
    getAllData({
      client,
      query: LIST_DIGITAL_LAB_INSTRUMENT_REPOSITORY_USERS,
      fetchPolicy: "network-only",
      dataPath: ["data", "listDigitalLabInstrumentRepositoryUsers"],
      drillData: true,
      variables: {
        limit: 1000
      }
    }),
    ...equipmentGroup.split("").map((character, index, charactersArray) => {
      return getAllData({
        client,
        query: LIST_IR_GROUP_ADMIN_MAPPINGS,
        fetchPolicy: "network-only",
        dataPath: ["data", "listIRGroupAdminMappings"],
        drillData: true,
        variables: {
          limit: 1000,
          groupName: {
            eq: charactersArray.slice(0, index + 1).join("")
          }
        }
      });
    })
  ]);
  const groupsReviewersIds = extractReviewersIds(groupsResults);

  let list = userItems
    .filter((item) => item?.userId !== currentUser?.user && groupsReviewersIds.includes(item?.userId))
    .map((item) => ({
      ...item,
      key: item?.email,
      value:
        item?.givenName && item?.familyName
          ? item?.name
            ? `${item?.givenName} ${item?.familyName} - ${item?.name}`
            : `${item?.givenName} ${item?.familyName}`
          : item.email
    }));
  return sortBy(list, "value");
}
