import React, { useContext, useEffect, useState } from "react";
import { ConfirmDialog } from "../../components/shared";
import { useFormikContext } from "formik";
import { commonPropsForInputsWithoutValue } from "../instruments/helpers";
import { OwcButton, OwcInput, OwcIcon, OwcTypography } from "@one/react";
import { OwcSelectWithChip } from "../../components/shared/OwcSelectWithChip";
import {
  CREATE_IR_GROUP_ADMIN_MAPPING,
  LIST_DIGITAL_LAB_INSTRUMENT_REPOSITORY_USERS,
  UNICITY_CHECK_LIST_IR_GROUP_ADMIN_MAPPINGS,
  UPDATE_IR_GROUP_ADMIN_MAPPING
} from "../../gql/landingapi";
import { getAllData } from "../../utils/helpers/fetching";
import { sortBy, uniqBy } from "lodash";
import Notify from "../notifications/Notify";
import { PaginationContext } from "../../components/shared/pagination/PaginationContext";
import { CircularProgress } from "@mui/material";

export const userLabel = (item) =>
  `${item.givenName} ${item.familyName} - ${item.userId?.split("roche.com_")[1] || item.userId}`;

const GroupDialog = ({ open, isEditMode, onCancel, client }) => {
  const { dispatchAction } = useContext(PaginationContext);
  const formik = useFormikContext();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ loading: false, error: false, optionsList: [] });
  const [users, setUsers] = useState([]);
  const handleClose = () => {
    formik.resetForm();
    onCancel();
  };

  useEffect(() => {
    const getUserList = async () => {
      setData({ ...data, loading: true, error: false });
      try {
        const { items } = await getAllData({
          client,
          query: LIST_DIGITAL_LAB_INSTRUMENT_REPOSITORY_USERS,
          fetchPolicy: "network-only",
          dataPath: ["data", "listDigitalLabInstrumentRepositoryUsers"],
          drillData: true,
          variables: {
            limit: 1000
          }
        });
        if (items) {
          const optionsList = items.map((item) => ({
            value: item.userId,
            label: `${item.givenName} ${item.familyName} - ${item.userId?.split("roche.com_")[1] || item.userId}`
          }));
          const uniqueOptionsList = uniqBy(optionsList, "value");
          const sortedOptionsList = sortBy(uniqueOptionsList, ["label"]);
          data.optionsList = sortedOptionsList;
          setUsers(items);
        }
      } catch (error) {
        data.error = error;
      } finally {
        setData({ ...data, loading: false });
      }
    };
    getUserList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  const checkUnicityForReason = async (groupName) => {
    let variables = { filter: groupName };
    const { items } = await getAllData({
      client,
      query: UNICITY_CHECK_LIST_IR_GROUP_ADMIN_MAPPINGS,
      variables,
      fetchPolicy: "network-only",
      dataPath: ["data", "listIRGroupAdminMappings"],
      drillData: false
    });
    return items.length > 0 ? false : true;
  };

  const getPersonalDetails = (data, users) => {
    const list = [];
    data?.forEach(({ value }) => {
      const user = users?.find((item) => item.userId === value);
      const { email, givenName, familyName, name, userId } = user;
      list.push({ email, givenName, familyName, name, userId });
    });
    return list;
  };

  const handleSubmit = async (data) => {
    let checkUnicityStatus = await checkUnicityForReason(data?.groupName?.trim());
    if (checkUnicityStatus || isEditMode) {
      setLoading(true);
      const row = {
        groupName: data.groupName,
        userIds: getPersonalDetails(data.userIds, users),
        reviewers: getPersonalDetails(data.reviewers, users)
      };

      try {
        await client.mutate({
          mutation: isEditMode ? UPDATE_IR_GROUP_ADMIN_MAPPING : CREATE_IR_GROUP_ADMIN_MAPPING,
          variables: row,
          fetchPolicy: "no-cache"
        });
        dispatchAction({
          type: "refresh"
        });
        handleClose();
        Notify({
          type: "success",
          icon: "circle_confirm",
          appName: "",
          text: isEditMode ? `Group updated successfully! ` : `Group created successfully! `
        });
      } catch (error) {
        Notify({
          type: "warning",
          icon: "caution",
          appName: "",
          text: isEditMode ? `Group updated failed!` : `Group created failed! `
        });
      } finally {
        setLoading(false);
      }
    } else {
      Notify({
        type: "warning",
        icon: "caution",
        appName: "",
        text: "Group already exist"
      });
    }
  };

  return (
    <ConfirmDialog
      open={open}
      close={handleClose}
      approveText={isEditMode ? "Update" : "Create"}
      approveColor="primary"
      approveVariant="contained"
      cancelText="Cancel"
      cancelVariant="outlined"
      cancelColor="primary"
      onCancel={handleClose}
      size="lg"
      isDivider
      testid={"import-file-dialog"}
      title={isEditMode ? "Edit group" : "Create group"}
      renderActions={({ cancelText, approveText }) => (
        <div style={{ display: "flex" }}>
          <OwcButton
            data-testid="confirm-dialog-actions-button-cancel"
            onClick={handleClose}
            variant="secondary"
            style={{ marginRight: 32 }}
          >
            {cancelText}
          </OwcButton>
          <OwcButton
            data-testid="confirm-dialog-actions-button-approve"
            onClick={() => {
              handleSubmit(formik.values);
            }}
            variant="primary"
            disabled={!(formik.isValid && formik.dirty) || formik.values?.groupName?.trim() === "" || loading}
          >
            <div style={{ display: "flex" }}>
              {loading && (
                <>
                  <CircularProgress size={20} />
                  &nbsp;
                </>
              )}
              {approveText}
            </div>
          </OwcButton>
        </div>
      )}
    >
      <OwcInput
        data-testid={`text-field-group-name`}
        label="Enter group name *"
        className="TextFieldInput"
        style={{ width: "100%" }}
        name="groupName"
        value={formik.values?.groupName}
        onInputChange={formik.handleChange}
        onBlur={formik.handleBlur}
        disabled={isEditMode}
        required
        {...commonPropsForInputsWithoutValue({
          formik,
          key: "groupName",
          dataTestIdKey: "groupName",
          dataTestIdForFormKey: "text-field"
        })}
      >
        {formik.errors["groupName"] && (
          <>
            <span
              // id={generateID.UUID(adminTopBannerMessage, `topBannerMessage`, "error_slot")}
              slot="error-text"
            >
              {formik.errors["groupName"] !== "Group name is required"
                ? formik.errors["groupName"]
                : !formik.values?.groupName && formik.touched.groupName === true
                ? formik.errors["groupName"]
                : ""}
            </span>
          </>
        )}
        {formik.values["groupName"] !== "" && (
          <OwcIcon
            name="circle_clear_filled"
            slot="suffix"
            type="legacy"
            onClick={() => formik.setFieldValue("groupName", "", true)}
          />
        )}
      </OwcInput>
      <div
        style={{
          marginTop: "24px",
          display: "grid",
          gridTemplateColumns: "calc(50% - 6px) calc(50% - 6px)",
          gridColumnGap: "12px"
        }}
      >
        <div>
          <OwcTypography variant="subtitle3">Users</OwcTypography>
          <OwcSelectWithChip
            required
            label="Select users *"
            dataKey="userIds"
            data={data}
            formik={formik}
            client={client}
            queryDetails={{
              query: LIST_DIGITAL_LAB_INSTRUMENT_REPOSITORY_USERS,
              dataPath: ["data", "listDigitalLabInstrumentRepositoryUsers"],
              valueKey: "userId",
              labelFunction: userLabel
            }}
            disableAll
            noChipsText="Selected users will appear here"
          />
        </div>
        <div>
          <OwcTypography variant="subtitle3">Reviewers</OwcTypography>
          <OwcSelectWithChip
            label="Select reviewers"
            dataKey="reviewers"
            data={data}
            formik={formik}
            client={client}
            queryDetails={{
              query: LIST_DIGITAL_LAB_INSTRUMENT_REPOSITORY_USERS,
              dataPath: ["data", "listDigitalLabInstrumentRepositoryUsers"],
              valueKey: "userId",
              labelFunction: userLabel
            }}
            disableAll
          />
        </div>
      </div>
    </ConfirmDialog>
  );
};

export default GroupDialog;
