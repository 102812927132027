import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { withApollo } from "react-apollo";
import { getContainerHeight, getEnv } from "../../utils/helpers/text/index";
import { connect, useSelector } from "react-redux";
import {
  agGridAutoGroupfieldName,
  allTableColumn,
  BULK_EDIT_ACTIVE,
  DEFAULT_COLUMNS,
  DEFAULT_FILTER,
  dimentionUnits,
  entryType,
  equipmentStatus,
  equipmentStatuses,
  filterKeyMapping,
  GROUP_LITERAL,
  GXPemptyInstrumentsOptions,
  LIMIT_EDIT_FILTER,
  listOfFieldAcceptOnlyInteger,
  listOfFieldsIgnoredForSearch,
  listOfFieldsSortable,
  listOfNumberFilter,
  literalFields,
  MODIFY_CLUSTER,
  MODIFY_INSTRUMENT,
  NORMAL_BULK_EDIT,
  OTHER_REASON,
  SEARCH_FILTER_QUERY,
  SHOW_INSTRUMENT,
  SPLITTER,
  subEquipment,
  systemStatusOptions,
  TOOL_PANELS
} from "../../constants";
import { compose } from "redux";
import _ from "underscore";
import {
  loadInstruments as loadInstrumentsAction,
  updateSearchFilter as updateSearchFilterAction
} from "./redux/actions";
import {
  OwcBadge,
  OwcButton,
  OwcIcon,
  OwcIconButton,
  OwcList,
  OwcListItem,
  OwcMenu,
  OwcRadio,
  OwcTooltip
} from "@one/react";
import {
  ACTIVATE_DIGITAL_LAB_INSTRUMENT_REPOSITORY_ENTRY,
  CREATE_DIGITAL_LAB_INSTRUMENT_PAGE_USER_FILTER,
  CREATE_IR_DISPLAY_COLUMN,
  DEACTIVATE_DIGITAL_LAB_INSTRUMENT_REPOSITORY_ENTRY,
  DECOMMISSION_CLUSTER,
  DELETE_DIGITAL_LAB_INSTRUMENT_PAGE_USER_FILTER,
  DELETE_IR_DISPLAY_COLUMN,
  GET_BULK_EXPORTING_EQUIPMENT,
  GET_DIGITAL_LAB_INSTRUMENT_REPOSITORY_ENTRY,
  GET_EXPORTING_EQUIPMENT,
  GET_WINSHUTTLE_EXPORT,
  LIST_DIGITAL_LAB_INSTRUMENT_REPOSITORY_USERS,
  LIST_INSTRUMENT_REPOSITORY_AUDITS,
  UPDATE_DIGITAL_LAB_INSTRUMENT_PAGE_USER_FILTER,
  UPDATE_DIGITAL_LAB_INSTRUMENT_USER_PROFILE_PERSONAL_FIELDS,
  UPDATE_EQUIPMENT_WITH_REVIEW,
  UPDATE_IR_DISPLAY_COLUMN
} from "../../gql/landingapi";
import { onlyUnique } from "../../utils/helpers/text";
import { filter, find, sortBy, uniq } from "lodash";
import { getAllData } from "../../utils/helpers/fetching";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import {
  updateClusterDetail as updateClusterDetailAction,
  updateEditClusterDetail as updateEditClusterDetailAction
} from "./cluster/redux/actions";
import { getAllMasterData, getBuilding, getFloor, getRoom } from "./LoadInstrumentsInfo";
import {
  updateLastDisplayColumn as updateLastDisplayColumnAction,
  updateLastFavFilter as updateLastFavFilterAction,
  updateLastPageSize as updateLastPageSizeAction,
  updateUserDisplayColumns as updateUserDisplayColumnsAction,
  updateUserFilters as updateUserFiltersAction
} from "../user/redux/actions";
import useDialog from "../../utils/hooks/useDialog";
import ConfirmDialog from "../../components/shared/ConfirmDialog";
import styled from "styled-components";
import Notify from "../notifications/Notify";
import omitDeep from "omit-deep-lodash";
import AuditTrialDialog from "./audit-trial/AuditTrialDialog";
import ShowEquipmentInfo from "./ShowEquipmentInfo";
import ReasonDropdown from "./ReasonDropdown";
import RefactoredReasonDropdown from "./RefactoredReasonDropdown";
import { DLabEquipmentList } from "@digitallab/grid-common-components";
import DATA_MODEL_TABLE from "../../utils/constants/dataModelTable";
import { getSortedUniqueList, getUniqValuesWithCaseInsensitive, sortedList } from "../importFile/utils/helpers";
import { OwcTypography } from "@one/react/dist";
import { Button, ButtonGroup, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from "@mui/material";
import { TogglerContext } from "../../contexts";
import {
  convertStrToObjColumns,
  getMegaClusterIds,
  onCreatefilter,
  onCreateSelectColumn,
  onDeleteSaveConfigurations,
  onUpdateFilter,
  onUpdateSelectColumn,
  saveLastPageSize
} from "./cluster/clusterListService";
import { getColumnObj } from "./cluster/steps/addEquipmentService";
import { CloudSearchConfig } from "../../components/shared/CloudSearchConfig";
import EquipmentAction from "./equipment-list/EquipmentAction";
import { CoverMenuItem } from "./addEditEquipment/AddEquipmentStyle";
import { ArrowDropDown } from "@mui/icons-material";
import { useHistory, useParams } from "react-router-dom";
import ExportDataDialog from "../../components/shared/export-data-dialog/ExportDataDialog";
import { ITEMS_LIST_VALUES } from "../../components/shared/export-data-dialog/consts";
import { convertToCamelCase, convertToSnakeCase } from "../../utils/helpers/converters";
import { getReviewers, isData4eye } from "../../utils/helpers/4eye";

export const DescriptionForm = styled.div`
  & > .button-box-equipment {
    padding: 6px 22px;
    font-size: 16px;
  }
`;
const currentEnv = getEnv();
export const CountHighlight = styled.div`
  font-size: 12px;
  font-style: normal;
  line-height: 23px;
  letter-spacing: 0;
  background-color: ${(props) => props.theme.notification.success.backgroundIconColor};
  color: ${(props) => props.theme.notification.success.backgroundColor};
  border-radius: 50%;
  width: 22px;
  display: flex;
  justify-content: center;
  margin-left: 10px;
`;

export const EquipAddedStyle = styled.div`
  display: flex;
  align-items: center;
`;

export const ActionsCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const TopBarButtons = styled.div`
  display: flex;
  margin-left: auto;
`;

export const loadFilterData = async ({ client, siteList, linkedInstanceList }) => {
  const masterData = await getAllMasterData({ client });
  const masterDataFilter = {};
  masterDataFilter[DATA_MODEL_TABLE?.equipmentCategory?.key] = [
    ...getUniqValuesWithCaseInsensitive(masterData?.listIREquipmentCategoryConfigurations?.items),
    ...getUniqValuesWithCaseInsensitive(masterData?.listIRClusterCategoryMappings?.items)
  ];
  masterDataFilter[DATA_MODEL_TABLE?.belongingToGroup?.key] = getUniqValuesWithCaseInsensitive(
    getSortedUniqueList(masterData?.listIRGroupMasters?.items, "value")
  );
  masterDataFilter[DATA_MODEL_TABLE?.manufacturer?.key] = getUniqValuesWithCaseInsensitive(
    masterData?.listIRManufacturerMasters?.items
  );
  masterDataFilter[DATA_MODEL_TABLE?.responsiblePerson?.key] = getUniqValuesWithCaseInsensitive(
    getSortedUniqueList(masterData?.listIRFilterLookups?.items, "filterValue")
  );
  masterDataFilter[DATA_MODEL_TABLE?.equipmentAdministrator?.key] = getUniqValuesWithCaseInsensitive(
    getSortedUniqueList(masterData?.listIREquipmentAdministrators?.items, "filterValue")
  );
  masterDataFilter[DATA_MODEL_TABLE?.siteName?.key] = getSortedUniqueList(siteList, "siteName");
  masterDataFilter[DATA_MODEL_TABLE?.tags?.key] = getSortedUniqueList(masterData?.listIRTags?.items, "filterValue");
  masterDataFilter[DATA_MODEL_TABLE?.linkedInstance?.key] = getUniqValuesWithCaseInsensitive(
    linkedInstanceList?.map((linkedInstance) => linkedInstance?.linkedInstanceDisplay)
  );

  masterDataFilter[DATA_MODEL_TABLE?.equipmentModel?.key] = getUniqValuesWithCaseInsensitive([
    ...masterData?.listIRequipmentModelConfigurations?.items,
    ...masterData?.listIRclusterModelConfigurations?.items
  ]);

  masterDataFilter[DATA_MODEL_TABLE?.variant?.key] = getUniqValuesWithCaseInsensitive(masterData?.listIRVariant?.items);

  masterDataFilter[DATA_MODEL_TABLE?.csv?.key] = sortedList(
    GXPemptyInstrumentsOptions[DATA_MODEL_TABLE?.csv?.key]?.map((obj) => obj?.value)
  );
  masterDataFilter[DATA_MODEL_TABLE?.electronicRecord?.key] = sortedList(
    GXPemptyInstrumentsOptions[DATA_MODEL_TABLE?.electronicRecord?.key]?.map((obj) => obj?.value)
  );
  masterDataFilter[DATA_MODEL_TABLE?.electronicSignatures?.key] = sortedList(
    GXPemptyInstrumentsOptions[DATA_MODEL_TABLE?.electronicSignatures?.key]?.map((obj) => obj?.value)
  );
  masterDataFilter[DATA_MODEL_TABLE?.qualificationStatus?.key] = sortedList(
    GXPemptyInstrumentsOptions[DATA_MODEL_TABLE?.qualificationStatus?.key]?.map((obj) => obj?.value)
  );
  masterDataFilter[DATA_MODEL_TABLE?.controlledEquipmentStatus?.key] = sortedList(
    GXPemptyInstrumentsOptions[DATA_MODEL_TABLE?.controlledEquipmentStatus?.key]?.map((obj) => obj?.value)
  );
  masterDataFilter[DATA_MODEL_TABLE?.gxpRelevant?.key] = sortedList(
    GXPemptyInstrumentsOptions[DATA_MODEL_TABLE?.gxpRelevant?.key]?.map((obj) => obj?.value)
  );

  masterDataFilter[DATA_MODEL_TABLE?.sop?.key] = getSortedUniqueList(masterData?.listIRSOPMasters?.items, "value");

  masterDataFilter[DATA_MODEL_TABLE?.systemStatus?.key] = systemStatusOptions;

  masterDataFilter[DATA_MODEL_TABLE?.status?.key] = equipmentStatuses;

  let linkedInstanceMappingObj = {};
  linkedInstanceList?.forEach((item) => {
    linkedInstanceMappingObj[item?.linkedInstance] = item?.linkedInstanceDisplay;
  });

  return { masterDataFilter, linkedInstanceMappingObj };
};

const getEquipment = async (id, client) => {
  try {
    const result = await client.query({
      query: GET_DIGITAL_LAB_INSTRUMENT_REPOSITORY_ENTRY,
      fetchPolicy: "no-cache",
      variables: {
        id: id
      }
    });
    if (result) {
      return result?.data?.getDigitalLabInstrumentRepositoryEntry;
    }
  } catch (err) {
    console.log("cluster check error", err);
  }
};

const deleteInstrumentAction =
  ({ client, setInstrumentToDelete, selectedReason, addComment, selectedReviewers, trackEvent }) =>
    async (instrument, setDataDelRes) => {
      if (isData4eye(instrument)) {
        return decommissionInstrumentAction({
          client,
          setInstrumentToDelete,
          selectedReason,
          addComment,
          selectedReviewers,
          trackEvent
        })(instrument, setDataDelRes);
      }
      trackEvent({ category: "Delete Equipment", action: "click-event" });
      try {
        const result = await client.mutate({
          mutation:
            instrument?.status === equipmentStatus?.active?.key
              ? DEACTIVATE_DIGITAL_LAB_INSTRUMENT_REPOSITORY_ENTRY
              : ACTIVATE_DIGITAL_LAB_INSTRUMENT_REPOSITORY_ENTRY,
          variables: {
            id: instrument.id,
            editReason: selectedReason,
            editComment: addComment
          },
          fetchPolicy: "no-cache"
        });
        if (result) {
          setDataDelRes(() => [`${instrument?.status[0]}-${new Date().getTime()}`]);
        }
        setInstrumentToDelete(null);
        Notify({
          type: "success",
          icon: "circle_confirm",
          appName: "",
          text:
            instrument?.status === equipmentStatus?.active?.key
              ? `Equipment deleted successfully! `
              : `Equipment restored successfully! `
        });
      } catch (e) {
        Notify({
          type: "warning",
          icon: "caution",
          appName: "",
          text:
            instrument?.status === equipmentStatus?.active?.key
              ? `Equipment deleted failed!`
              : `Equipment restored failed!`
        });
      }
    };

const decommissionInstrumentAction =
  ({ client, setInstrumentToDelete, selectedReason, addComment, selectedReviewers, trackEvent }) =>
    async (instrument, setDataDelRes) => {
      trackEvent({ category: "Decommission Equipment", action: "click-event" });
      try {
        const reviewers = selectedReviewers.map((reviewer) => reviewer.userId);
        const result = await client.mutate({
          mutation: UPDATE_EQUIPMENT_WITH_REVIEW,
          variables: {
            inventoryId: instrument.id,
            newData: {
              id: instrument.id,
              status: equipmentStatus.deleted.key,
              editReason: selectedReason,
              editComment: addComment,
              reviewers
            },
            reviewers
          },
          fetchPolicy: "no-cache"
        });
        if (result) {
          setDataDelRes(() => [`${instrument?.status[0]}-${new Date().getTime()}`]);
        }
        setInstrumentToDelete(null);
        Notify({
          type: "success",
          icon: "circle_confirm",
          appName: "",
          text: "Request to remove equipment has been sent to the reviewer(s) successfully."
        });
      } catch (e) {
        Notify({
          type: "warning",
          icon: "caution",
          appName: "",
          text: "Request to remove equipment has failed."
        });
      }
    };

/**
 * Deleting cluster equipment .
 * @param {Object} client - The object is to make api call's.
 * @param {Object} setInstrumentToDelete- The object is to set delete current instrument cluster.
 * @param {function} dispatchAction - The function is to fetch List data.
 * @param {string} selectedReason - The string is for select reason string.
 * @param {string} comment - User's comment.
 * @param {function} trackEvent - Function to track user's action.
 */

const deleteClusterAction =
  ({ client, setInstrumentToDelete, dispatchAction, selectedReason, comment, trackEvent }) =>
    async (instrument, setDataDelRes) => {
      trackEvent({ category: "Delete cluster", action: "click-event" });
      try {
        const result = await client.mutate({
          mutation: DECOMMISSION_CLUSTER,
          variables: {
            clusterId: instrument.id,
            editReason: selectedReason,
            editComment: comment
          },
          fetchPolicy: "no-cache"
        });
        if (result) {
          setDataDelRes(() => [`${instrument?.status[0]}-${new Date().getTime()}`]);
        }
        dispatchAction({
          type: "setTableEditable",
          payload: true
        });
        dispatchAction({
          type: "refresh"
        });
        setInstrumentToDelete(null);
        Notify({
          type: "success",
          icon: "circle_confirm",
          appName: "",
          text: "Cluster decommission successfully! "
        });
      } catch (e) {
        dispatchAction({
          type: "setTableEditable",
          payload: false
        });
        Notify({
          type: "warning",
          icon: "caution",
          appName: "",
          text: "Cluster decommission failed!"
        });
      }
    };

const handleClusterFieldValuesForEdit = async (instrument, client) => {
  let tempInstrumentDetail = omitDeep(instrument, "__typename");

  if (tempInstrumentDetail.gxpRelevant) {
    tempInstrumentDetail.gxpRelevant =
      find(GXPemptyInstrumentsOptions?.gxpRelevant, {
        key: tempInstrumentDetail?.gxpRelevant
      }) || null;
  }

  if (tempInstrumentDetail.qualificationStatus) {
    tempInstrumentDetail.qualificationStatus =
      find(GXPemptyInstrumentsOptions?.qualificationStatus, {
        key: tempInstrumentDetail?.qualificationStatus
      }) || null;
  }

  if (tempInstrumentDetail?.buildingLocation) {
    const building = await getBuilding(client, tempInstrumentDetail?.buildingLocation?.key);
    tempInstrumentDetail.buildingLocation = building?.isActive ? building : null;
  }

  if (tempInstrumentDetail?.floor) {
    const floor = await getFloor(client, tempInstrumentDetail?.floor?.key);
    tempInstrumentDetail.floor = floor?.isActive ? floor : null;
  }
  if (tempInstrumentDetail?.room) {
    const room = await getRoom(client, tempInstrumentDetail?.room?.key);
    tempInstrumentDetail.room = room?.isActive ? room : null;
  }

  if (tempInstrumentDetail?.responsiblePerson) {
    tempInstrumentDetail.responsiblePerson =
      tempInstrumentDetail?.responsiblePerson === "null" ? null : tempInstrumentDetail?.responsiblePerson;
  }
  if (tempInstrumentDetail?.equipmentNickName) {
    tempInstrumentDetail.equipmentNickName =
      tempInstrumentDetail?.equipmentNickName === "null" ? null : tempInstrumentDetail?.equipmentNickName;
  }
  if (tempInstrumentDetail?.equipmentAdministrator) {
    tempInstrumentDetail.equipmentAdministrator =
      tempInstrumentDetail?.equipmentAdministrator === "null" ? null : tempInstrumentDetail?.equipmentAdministrator;
  }

  if (tempInstrumentDetail?.belongingToGroup) {
    tempInstrumentDetail.belongingToGroup =
      tempInstrumentDetail?.belongingToGroup === "null" ? null : tempInstrumentDetail?.belongingToGroup;
  }

  if (tempInstrumentDetail?.cluster) {
    tempInstrumentDetail.cluster = tempInstrumentDetail?.cluster === "null" ? null : tempInstrumentDetail?.cluster;
  }

  if (tempInstrumentDetail?.responsibleProxy) {
    tempInstrumentDetail.responsibleProxy =
      tempInstrumentDetail?.responsibleProxy === "null" ? null : tempInstrumentDetail?.responsibleProxy;
  }
  if (tempInstrumentDetail?.softwareVersion) {
    tempInstrumentDetail.softwareVersion =
      tempInstrumentDetail?.softwareVersion === "null" ? null : tempInstrumentDetail?.softwareVersion;
  }

  tempInstrumentDetail?.subEquipment?.items.map((x) => {
    if (x?.isClusterSWVersion === true) {
      tempInstrumentDetail.leadingSoftwareVersionId = x.id;
    } else {
      x.isClusterSWVersion = false;
    }
    return x;
  });

  return tempInstrumentDetail;
};

const InstrumentsTable = ({
  instruments,
  loadInstruments,
  client,
  addInstrumentClicked,
  updateSearchFilter,
  setActiveModule,
  updateClusterDetail,
  updateEditClusterDetail,
  setIsEditMode,
  editClusterDetail,
  activeModule,
  updateLastDisplayColumn,
  updateUserDisplayColumns,
  updateLastFavFilter,
  updateUserFilters,
  updateLastPageSize,
  selectedEquipmentId,
  setSelectedEquipmentId,
  bulkImportFilters,
  setBulkImportFilters,
  enableCheckbox,
  setEnableCheckbox,
  setQueryChoice,
  searchFilterCount,
  setSearchFilterCount,
  onReviewOrRevert
}) => {
  const [instrumentToDelete, setInstrumentToDelete] = useState(null);
  const { openDialog, ...dialogProps } = useDialog();
  const [selectedReason, setSelectedReason] = useState("");
  const [selectedReviewers, setSelectedReviewers] = useState([]);
  const [addComment, setAddComment] = useState("");
  const { trackEvent } = useMatomo();
  const [loading, setLoading] = useState(false);
  const [auditLogs, setAuditLogs] = useState();
  const [userLists, setUserLists] = useState([]);
  const [reviewers, setReviewers] = useState([]);
  const [openAuditTrial, setOpenAuditTrial] = useState(false);
  const [bulkExportStatus, setBulkExportStatus] = useState(ITEMS_LIST_VALUES.NONE);
  const [dataDelRes, setDataDelRes] = useState([]);
  const [dataModel, setDataModel] = useState({});
  const [exportDisable, setExportDisable] = useState(true);
  const [downloadBulkCSVDisable, setDownloadBulkCSVDisable] = useState(true);
  const [masterDataFilters, setMasterDataFilters] = useState({});
  const [additionalFields, setAdditionalFields] = useState([]);
  const [attributes, setAttributes] = useState({});
  const [defaultShowColumns, setDefaultShowColumns] = useState([]);
  const [units, setUnits] = useState("metric");
  const [showDimensionList, setShowDimensionList] = useState(false);
  // attaches element to an anchor and describes if dropdown menu for exports is visible or not
  const [anchorEl, setAnchorEl] = useState(null);
  const [dimensionAnchor, setDimensionAnchor] = useState(null);
  const [loadingEditFilterButton, setLoadingEditFilterButton] = useState(true);
  const [exportDataModalVisible, setExportDataModalVisible] = useState(false);
  const currentUser = useSelector((state) => state?.user);
  const {
    userFilters,
    myLabFilter,
    lastPageSize,
    lastFavFilter,
    lastDisplayColumns,
    userDisplays,
    systemDisplays,
    email,
    actionStatus,
    groups,
    id: userID,
    user: fullUserID
  } = currentUser;
  const userDisplayList = useRef([]);
  const megaclusterIds = useRef([]);
  const masterDataAutoGroup = useRef({});
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const { InventoryId } = useParams();
  const navigation = useHistory();
  const localUserFilters = useRef(userFilters);

  const autoGroupColField = "equipment_model";

  const agGridOptions = {
    componentsAdded: selectedEquipmentId?.length
  };

  const remainingHeight = enableCheckbox ? 286 : 225; //  header + footer + pageHeader
  const [containerHeight, setContainerHeight] = useState(getContainerHeight(currentEnv, remainingHeight));
  window.addEventListener("resize", function () {
    setContainerHeight(getContainerHeight(currentEnv, remainingHeight));
  });
  useEffect(() => {
    setContainerHeight(getContainerHeight(currentEnv, remainingHeight));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableCheckbox]);

  useEffect(() => {
    localUserFilters.current = userFilters;
  }, [userFilters]);

  const onNavigatePath = InventoryId
    ? undefined
    : () => {
      navigation.push("/");
    };

  /**
   * handles onClick action for three dots menu for export
   * @param {Event} event -event
   * @returns void
   */
  const handleSettingClick = (event) => {
    anchorEl ? setAnchorEl(null) : setAnchorEl(event.currentTarget);
  };

  const handleSettingClose = () => {
    setAnchorEl(null);
  };

  const addInstrumentClick = (state, isEditMode, item) => {
    addInstrumentClicked(state, isEditMode, item);
    if (item?.entryType === entryType?.cluster) {
      setActiveModule(MODIFY_CLUSTER);
    } else {
      setActiveModule(MODIFY_INSTRUMENT);
    }
  };

  const createCluster = () => {
    setIsEditMode(false);
    setActiveModule(MODIFY_CLUSTER);
  };

  /**
   * The `bulkExportStatus` change handler. It send request for bulk edit export
   * when state is `bulkchange`, export csv when it is `csv` and winshuttle export when it is `winshuttle`
   * @returns {Promise<void>}
   */
  const getExportSearchedInstrumentList = async () => {
    updateSearchFilter();
    // ' AND' instead of 'AND' due to string part stANDalone_equipment
    let splitSearchValues = bulkImportFilters?.searchValue?.split(SPLITTER.AND) ?? [];
    let removeIndex = -1;
    let isSelectAll = true;
    let applyStatuses = [];
    if (bulkImportFilters?.searchValue?.includes(`status:"${equipmentStatus?.active?.value}"`)) {
      applyStatuses.push(`status:'${equipmentStatus?.active?.value}'`);
    }
    if (bulkImportFilters?.searchValue?.includes(`status:"${equipmentStatus?.pending?.value}"`)) {
      let value = bulkExportStatus === ITEMS_LIST_VALUES.NONE ? "dummy" : equipmentStatus?.pending?.value;
      applyStatuses.push(`status:'${value}'`);
    }
    if (bulkImportFilters?.searchValue?.includes(`status:"${equipmentStatus?.deleted?.value}"`)) {
      if (!applyStatuses?.includes(`status:'dummy'`)) applyStatuses.push(`status:'dummy'`);
    }

    // Optimized code here
    const len = splitSearchValues.length;
    for (let i = 0; i < len; i++) {
      const v = splitSearchValues[i];
      if (v.includes("status:")) {
        removeIndex = i;
        isSelectAll = v.includes("status:*");
        break;
      }
    }

    if (removeIndex !== -1) {
      splitSearchValues?.splice(removeIndex, 1);
    }

    if (!isSelectAll) {
      const strValue = applyStatuses?.join(SPLITTER.OR);
      // if we have a string that is not empty
      if (strValue) {
        splitSearchValues?.push(`(${strValue})`);
      }
    } else if (bulkExportStatus === ITEMS_LIST_VALUES.NONE || !bulkImportFilters?.searchValue) {
      splitSearchValues?.push(`(status:'${equipmentStatus?.active?.value}')`);
    } else {
      splitSearchValues?.push(
        `(status:'${equipmentStatus?.active?.value}' OR status:'${equipmentStatus?.pending?.value}')`
      );
    }
    let searchQuery = _.compact(splitSearchValues).join(SPLITTER.AND);
    let filterDefinitionObj = {
      query: searchQuery || "*",
      /* For Future Reference */
      // filterQuery,
      sort: `${bulkImportFilters?.sortBy === "position_in_cluster"
        ? convertToSnakeCase(DATA_MODEL_TABLE?.serialNumber?.key)
        : bulkImportFilters?.sortBy
        } ${bulkImportFilters?.sort}`,
      start: 0
    };

    if (bulkExportStatus === ITEMS_LIST_VALUES.BULK_CHANGE) {
      filterDefinitionObj.filterQuery = `(or entry_type:'${subEquipment}' entry_type:'${entryType?.standaloneEquipment}')`;
    }

    if (bulkImportFilters?.searchValue) {
      filterDefinitionObj = {
        ...filterDefinitionObj
      };
      if (bulkImportFilters?.queryOptions) {
        filterDefinitionObj.queryOptions = bulkImportFilters?.queryOptions;
      }
    }

    let variablesObj = {
      filterDefinition: JSON.stringify(filterDefinitionObj),
      listQuery: ""
    };

    let query = GET_EXPORTING_EQUIPMENT;
    let dataPath = ["data", "getExportEquipmentData"];

    if ([ITEMS_LIST_VALUES.CSV_EQUIPMENT, ITEMS_LIST_VALUES.WINSHUTTLE_EQUIPMENT].includes(bulkExportStatus)) {
      let selectedColumns =
        typeof lastDisplayColumns === "string" ? JSON.parse(lastDisplayColumns) : lastDisplayColumns;
      const showColumns =
        typeof selectedColumns?.displayDefinition?.showColumns === "string"
          ? JSON.parse(selectedColumns?.displayDefinition?.showColumns)
          : selectedColumns?.displayDefinition?.showColumns;

      const defaultShowColumns = filter(allTableColumn(), { show: true });
      const columns =
        showColumns?.length === 0 ? defaultShowColumns?.map((x) => x.key) : showColumns?.map((x) => x.key);
      variablesObj.selectedColumns = `${columns.join()},${DATA_MODEL_TABLE?.equipmentModel?.key}`;
      variablesObj.dimensionUnit = units;
    }

    if (bulkExportStatus === ITEMS_LIST_VALUES.BULK_CHANGE) {
      query = GET_BULK_EXPORTING_EQUIPMENT;
      dataPath = ["data", "getDownloadEquipmentForBulkChange"];
    }

    if (bulkExportStatus === ITEMS_LIST_VALUES.WINSHUTTLE_EQUIPMENT) {
      query = GET_WINSHUTTLE_EXPORT;
      dataPath = ["data", "getWinshuttleExport"];
      const fromDate = new Date();
      const toDate = new Date();
      fromDate.setUTCHours(fromDate.getUTCHours() - 168); // set form date a week ago
      variablesObj.fromDate = fromDate.toISOString();
      variablesObj.toDate = toDate.toISOString();
    }

    await getAllData({
      client,
      query,
      variables: variablesObj,
      dataPath,
      drillData: false
    });
    setBulkExportStatus(ITEMS_LIST_VALUES.NONE);
  };

  /**
   * For the 'bulkExportStatus' state change when user select the type of the
   * export
   */
  useEffect(() => {
    // TODO add error handling
    if (bulkExportStatus !== ITEMS_LIST_VALUES.NONE) {
      // TODO should get from the AG Grid
      getExportSearchedInstrumentList().catch((error) => {
        // TODO add here correct error handling from AWS, now is temporary solution
        console.error(error);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bulkExportStatus]);

  const titleText =
    instrumentToDelete?.status === equipmentStatus?.active?.key
      ? instrumentToDelete?.entryType === entryType?.cluster
        ? "Decommission cluster"
        : isData4eye(instrumentToDelete)
          ? "Reason for deleting"
          : "Delete equipment"
      : instrumentToDelete?.entryType === entryType?.cluster
        ? "Restore cluster"
        : "Restore equipment";
  const titleTextButton =
    instrumentToDelete?.status === equipmentStatus?.active?.key
      ? instrumentToDelete?.entryType === entryType?.cluster
        ? "Decommission"
        : "Delete equipment"
      : instrumentToDelete?.entryType === entryType?.cluster
        ? "Restore cluster"
        : "Restore equipment";
  const contentText =
    instrumentToDelete?.status === equipmentStatus?.active?.key
      ? instrumentToDelete?.entryType === entryType?.cluster
        ? "decommission"
        : "delete"
      : "restore";
  const otherText =
    instrumentToDelete?.status === equipmentStatus?.active?.key
      ? instrumentToDelete?.entryType === entryType?.cluster
        ? "decommissioning"
        : "deleting"
      : "restoring";
  const approveColor = instrumentToDelete?.status === equipmentStatus?.active?.key ? "primary" : "primary";

  const editEquipment = async (item) => {
    const result = await getEquipment(item?.id, client);
    if (item?.entryType === entryType?.cluster) {
      let editData = await handleClusterFieldValuesForEdit(result, client);
      editData.subEquipment.items = sortBy(editData.subEquipment.items, "positionInCluster");
      editData = omitDeep({ ...editData }, "__typename");
      if (Object.keys(editClusterDetail).length === 0) {
        updateEditClusterDetail({ ...editData });
      }
      updateClusterDetail({ ...editData });
      addInstrumentClick(true, true, result);
    } else {
      addInstrumentClick(true, true, result);
    }
  };

  const loadAuditTrailList = async (item) => {
    try {
      setOpenAuditTrial(true);
      setLoading(true);
      let { items: auditLogChanges } = await getAllData({
        client,
        query: LIST_INSTRUMENT_REPOSITORY_AUDITS,
        variables: {
          inventoryId: item?.id
        },
        dataPath: ["data", "listInstrumentRepositoryAudits"],
        drillData: true
      });

      // There are cases where actual userId is being used, so we need to convert it to name.
      setAuditLogs(
        auditLogChanges?.map((auditLogItem) => {
          const foundUser = find(userLists, (user) => user.userId === auditLogItem.modifiedByUserId);
          if (foundUser) {
            auditLogItem.modifiedByUserId = foundUser.name;
            auditLogItem.modifiedByEmail = foundUser.email;
          }
          return auditLogItem;
        }) ?? []
      );
    } catch (err) {
      console.warn(err);
      setAuditLogs([]);
    } finally {
      setLoading(false);
    }
  };

  /**
   *
   * @param item
   * @return {Promise<void>}
   */
  const deleteEquipment = async (item) => {
    const result = await getEquipment(item?.id, client);
    setInstrumentToDelete(result);
    const reviewersList = await getReviewers(client, result.belongingToGroup, currentUser);
    setReviewers(reviewersList);
    openDialog();
  };

  const handleCloseAuditTrial = () => {
    setOpenAuditTrial(false);
  };

  const { isVisible, toggleMenu } = useContext(TogglerContext);

  const getCloudSearchOptions = (data) => {
    setBulkImportFilters(data);
  };

  //============================AG-GRID-COMMON=================
  useEffect(() => {
    // TODO `userDisplayList` is declared as array, but it used here as an Object, what the heck
    userDisplayList.current = [...userDisplays];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let siteList = useSelector((state) => state?.user?.sites);
  const linkedInstanceList = useSelector((state) => state.instruments?.linkedInstanceList);

  const autoGroupColumnDef = useMemo(() => {
    return {
      headerName: "Model",
      field: autoGroupColField,
      sortable: true,
      pinned: "left",
      lockPinned: true,
      filter: "agSetColumnFilter",
      filterParams: {
        buttons: ["reset"],
        defaultToNothingSelected: true,
        values: (params) => {
          const values = masterDataAutoGroup?.current[DATA_MODEL_TABLE?.equipmentModel?.key] || [];
          params.success(values);
        }
      },
      showDisabledCheckboxes: enableCheckbox,
      cellStyle: (params) => {
        if (params?.data?.id && params?.data?.id[0] === "dummyRow") {
          return { display: "none" };
        }
        return params?.node?.level > 0 &&
          (params?.data?.entry_type[0] === entryType?.cluster ||
            params?.data?.entry_type[0] === entryType?.clusterSubequipment)
          ? {
            border: "none !important",
            backgroundColor: "#F5F5F2",
            backgroundImage: "conic-gradient(at 7% 90%, #FFFFFF 180deg, #F5F5F2 180deg)"
          }
          : {
            marginLeft: "none !important"
          };
      },
      cellRendererParams: {
        checkbox: (params) => {
          let UserViewerStatus = false;
          if (
            (actionStatus.hasRole === "User" || actionStatus.hasRole === "Viewer") &&
            params?.data?.status[0] === equipmentStatus?.active?.key
          ) {
            groups?.forEach((x) => {
              if (params?.data["belonging_to_group"][0].startsWith(x.groupName)) {
                UserViewerStatus = true;
              }
            });
          }
          let updateCheckBox =
            params?.data?.entry_type[0] === "CLUSTER" || params?.data?.entry_type[0] === "CLUSTER-SUBEQUIPMENT"
              ? false
              : actionStatus.hasRole === "Admin" && params?.data?.status?.[0] === equipmentStatus?.active?.key
                ? true
                : UserViewerStatus;
          return enableCheckbox ? updateCheckBox : enableCheckbox;
        },
        innerRenderer: (params) => {
          return params?.data?.equipment_model[0];
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableCheckbox]);

  const onCheckBoxSelected = ({ id = "", updatedIds = null }) => {
    if (id) {
      setSelectedEquipmentId((prevState) => {
        return onlyUnique([...prevState, id]);
      });
    }
    if (updatedIds) {
      setSelectedEquipmentId(() => {
        return updatedIds;
      });
    }
  };
  /**
   * AG Grid settings and data load
   */
  useEffect(() => {
    const loadUserList = async () => {
      let { items: userList } = await getAllData({
        client,
        query: LIST_DIGITAL_LAB_INSTRUMENT_REPOSITORY_USERS,
        fetchPolicy: "network-only",
        dataPath: ["data", "listDigitalLabInstrumentRepositoryUsers"],
        drillData: true,
        variables: {
          limit: 1000
        }
      });
      setUserLists(userList);
    };

    const loadMasterData = async () => {
      const { masterDataFilter } = await loadFilterData({
        client,
        siteList,
        linkedInstanceList
      });
      masterDataAutoGroup.current = { ...masterDataFilter };
      setMasterDataFilters({ ...masterDataFilter });
    };

    const loadMegaClusterIds = async () => {
      const ids = await getMegaClusterIds();
      megaclusterIds.current = uniq(ids);
    };

    loadUserList().catch(console.error);
    loadMasterData().catch(console.error);
    loadMegaClusterIds().catch(console.error);

    let attr = {
      rowModelType: "serverSide",
      rowQuickSearch: false,
      treeData: true,
      suppressPaginationPanel: true,
      pagination: true,
      animateRows: true,
      rowSelection: "multiple",
      rowMultiSelectWithClick: true,
      rowExport: false,
      masterDetail: true,
      isGroupOpenByDefault: false,
      defaultToolPanel: "filters",
      hiddenByDefault: true,
      serverSideInfiniteScroll: false,
      serverSideSortOnServer: true,
      suppressRowClickSelection: true
    };

    setAttributes(() => attr);

    // eslint-disable-next-line
  }, []);

  const actionRender = (params) => {
    return (
      <>
        <EquipmentAction
          editEquipment={editEquipment}
          loadAuditTrailList={loadAuditTrailList}
          deleteEquipment={deleteEquipment}
          actionStatus={actionStatus}
          groups={groups}
          params={params}
          fullUserID={fullUserID}
          onReviewOrRevert={onReviewOrRevert}
        />
      </>
    );
  };
  /**
   * AG Grid sidebar? filters settings and handlers
   */
  useEffect(() => {
    const unorderedHidenColumn = [];
    const orderedShowColumns = sortBy(
      convertStrToObjColumns(lastDisplayColumns)?.displayDefinition?.showColumns,
      ["order"],
      ["asc"]
    );
    allTableColumn()?.forEach((value) => {
      const obj = find(orderedShowColumns, (v) => v.key === value.key);
      if (!obj) {
        unorderedHidenColumn.push(value);
      }
    });
    const redefinedColumns = [...orderedShowColumns, ...unorderedHidenColumn]?.map((value) => {
      return getColumnObj(value, masterDataFilters, convertToCamelCase(autoGroupColField), units);
    });

    const defaultShowCols = filter(allTableColumn(), { show: true });
    setDefaultShowColumns(() => {
      return defaultShowCols?.map((value) => {
        return getColumnObj(value, masterDataFilters, convertToCamelCase(autoGroupColField), units);
      });
    });

    const aditionColumns = [
      {
        field: "actions",
        headerName: "Actions",
        maxWidth: 50,
        filter: false,
        pinned: "right",
        lockVisible: true,
        lockPosition: "right",
        cellClass: "action-render",
        cellRenderer: actionRender
      }
    ];
    setAdditionalFields(() => [...aditionColumns]);
    setDataModel([...redefinedColumns]);
    // eslint-disable-next-line
  }, [masterDataFilters, units]);

  const onSave = async ({
    currentState,
    chipName = "",
    type = "update",
    isAgGridObj = false,
    pannel = "",
    isSaveConfigUpdated = false,
    updateRedux = true
  }) => {
    let result = null;
    if (pannel === TOOL_PANELS?.filters) {
      let obj = {};
      if (isAgGridObj) {
        for (const key in DEFAULT_FILTER.filterDefinition) {
          if (currentState[convertToSnakeCase(filterKeyMapping[key] || key)] === undefined) {
            obj[key] = DEFAULT_FILTER.filterDefinition[key];
          } else if (currentState[convertToSnakeCase(filterKeyMapping[key] || key)]?.filterType === "set") {
            let values = currentState[convertToSnakeCase(filterKeyMapping[key] || key)]?.values;
            if (
              filterKeyMapping[key] === DATA_MODEL_TABLE?.equipmentModel?.key ||
              key === DATA_MODEL_TABLE?.equipmentModel?.key
            ) {
              if (Object?.keys(currentState)?.includes(agGridAutoGroupfieldName)) {
                values = uniq([...values, ...currentState[agGridAutoGroupfieldName]?.values]);
              }
            }
            obj[key] = values;
          } else {
            obj[key] = currentState[convertToSnakeCase(filterKeyMapping[key] || key)];
          }
        }
      } else {
        obj = {
          ...currentState
        };
      }

      const lastSavedFilter = {
        filterDefinition: obj,
        filterName: chipName
      };
      if (type === "create") {
        lastSavedFilter.userID = userID;
        lastSavedFilter.chipName = chipName;
        result = await onCreatefilter({
          id: userID,
          email,
          userFilters: localUserFilters.current,
          lastFavFilter: lastSavedFilter,
          client,
          query: CREATE_DIGITAL_LAB_INSTRUMENT_PAGE_USER_FILTER,
          userProfileQuery: UPDATE_DIGITAL_LAB_INSTRUMENT_USER_PROFILE_PERSONAL_FIELDS,
          updateUserFilters,
          updateLastFavFilter
        });
      } else {
        result = await onUpdateFilter({
          lastFavFilter: lastSavedFilter,
          isSaveConfigUpdated: isSaveConfigUpdated,
          updateRedux,
          id: userID,
          email,
          client,
          query: UPDATE_DIGITAL_LAB_INSTRUMENT_PAGE_USER_FILTER,
          userProfileQuery: UPDATE_DIGITAL_LAB_INSTRUMENT_USER_PROFILE_PERSONAL_FIELDS,
          updateLastFavFilter
        });
      }
      return { savedObj: lastSavedFilter, result };
    } else if (pannel === TOOL_PANELS?.columns) {
      const savedColumns = [...currentState];
      const updatedSavedColumns = savedColumns.map((column, index) => {
        const dataModelKey = convertToCamelCase(column?.colId);
        return {
          key: dataModelKey,
          val: DATA_MODEL_TABLE[dataModelKey]?.value || "ignore",
          order: index,
          show: DATA_MODEL_TABLE[dataModelKey]?.value?.length ? !column?.hide : "ignore",
          sortStatus: listOfFieldsSortable.includes(DATA_MODEL_TABLE[dataModelKey]?.key)
        };
      });
      const showColumns = filter(updatedSavedColumns, { show: true });
      const hideColumns = filter(updatedSavedColumns, { show: false });
      let savecolumnObj = {
        displayName: chipName,
        displayDefinition: {
          showColumns,
          hideColumns
        }
      };
      if (type === "create") {
        savecolumnObj.userID = userID;
        savecolumnObj.chipName = chipName;
        result = await onCreateSelectColumn({
          id: userID,
          email,
          userDisplayList,
          unParsedData: savecolumnObj,
          client,
          query: CREATE_IR_DISPLAY_COLUMN,
          userProfileQuery: UPDATE_DIGITAL_LAB_INSTRUMENT_USER_PROFILE_PERSONAL_FIELDS,
          updateUserDisplayColumns,
          updateLastDisplayColumn
        });
      } else {
        result = await onUpdateSelectColumn({
          data: JSON.stringify(savecolumnObj),
          isSaveConfigUpdated,
          updateRedux,
          id: userID,
          email,
          client,
          userProfileQuery: UPDATE_DIGITAL_LAB_INSTRUMENT_USER_PROFILE_PERSONAL_FIELDS,
          query: UPDATE_IR_DISPLAY_COLUMN,
          userDisplayList,
          updateUserDisplayColumns,
          updateLastDisplayColumn
        });
      }
      return { savedObj: savecolumnObj, result };
    }
  };

  const isServerSideGroup = useCallback((dataItem) => {
    // indicate if node is a group

    if (dataItem?.entry_type && dataItem?.entry_type?.includes(entryType?.cluster)) {
      return dataItem?.id;
    }

    return "";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getServerSideGroupKey = useCallback((dataItem) => {
    // specify which group key to use

    if (dataItem?.entry_type && dataItem?.entry_type?.includes(entryType?.cluster)) {
      return dataItem?.id;
    }

    return "";
  }, []);

  const onChipDelete = async (chipToDelete, lastSavedChip, panel, userFilters) => {
    return await onDeleteSaveConfigurations({
      userID,
      chipToDelete,
      panel,
      lastSavedChip,
      client,
      email,
      deleteFilterQuery: DELETE_DIGITAL_LAB_INSTRUMENT_PAGE_USER_FILTER,
      deleteColumnQuery: DELETE_IR_DISPLAY_COLUMN,
      userProfileQuery: UPDATE_DIGITAL_LAB_INSTRUMENT_USER_PROFILE_PERSONAL_FIELDS,
      userFilters,
      userDisplayList,
      updateUserFilters,
      updateUserDisplayColumns,
      updateLastFavFilter,
      updateLastDisplayColumn
    });
  };

  const onResetAll = async () => {
    if (InventoryId) {
      navigation.push('/');
    } else {
      await onUpdateFilter({
        lastFavFilter: DEFAULT_FILTER,
        updateRedux: true,
        id: userID,
        email,
        client,
        query: UPDATE_DIGITAL_LAB_INSTRUMENT_PAGE_USER_FILTER,
        userProfileQuery: UPDATE_DIGITAL_LAB_INSTRUMENT_USER_PROFILE_PERSONAL_FIELDS,
        updateLastFavFilter
      });
      await onUpdateSelectColumn({
        data: JSON.stringify(DEFAULT_COLUMNS),
        updateRedux: true,
        id: userID,
        email,
        client,
        userProfileQuery: UPDATE_DIGITAL_LAB_INSTRUMENT_USER_PROFILE_PERSONAL_FIELDS,
        query: UPDATE_IR_DISPLAY_COLUMN,
        userDisplayList,
        updateUserDisplayColumns,
        updateLastDisplayColumn
      });
    }
  };

  const onRowsPerChange = async (rowPerPage) => {
    await saveLastPageSize({
      limit: rowPerPage,
      limitKey: "ir_grid",
      lastPageSize,
      query: UPDATE_DIGITAL_LAB_INSTRUMENT_USER_PROFILE_PERSONAL_FIELDS,
      updateLastPageSize,
      id: userID,
      email,
      client
    });
  };

  const requestServer = async (cloudSearchConfigOption) => {
    return CloudSearchConfig(cloudSearchConfigOption);
  };

  const searchFilterData = useCallback(() => {
    const belongingToGroupKey = convertToSnakeCase(DATA_MODEL_TABLE?.belongingToGroup?.key);
    /* columnFilterGroups will have the list of column filter groups */
    let filterSplit = bulkImportFilters?.searchValue?.split(" ");
    let columnFilterGroups = [];

    filterSplit.forEach((value) => {
      if (value?.includes(belongingToGroupKey)) {
        let groupStr = value?.split(":")[1];
        groupStr = groupStr?.replaceAll(")", "");
        groupStr = groupStr?.replaceAll(`"`, "");
        columnFilterGroups.push(groupStr);
      }
    });
    /* columnFilterGroups will have the list of column filter groups */

    let groupName = "";
    let userGroupStrs = [];
    let groupCloumnFilterStrs = [];
    if (actionStatus?.hasRole === "User" && groups.length > 0) {
      groups.forEach((userGroup) => {
        if (bulkImportFilters?.searchValue?.includes(belongingToGroupKey) && columnFilterGroups?.length > 0) {
          columnFilterGroups?.forEach((groupStr) => {
            if (groupStr?.startsWith(userGroup?.groupName)) {
              let checkDuplicateObj = `${belongingToGroupKey}:${groupStr}`;
              const addGroups = find(groupCloumnFilterStrs, (strValue) => {
                if (strValue === checkDuplicateObj) {
                  return true;
                }
              });
              if (!addGroups) groupCloumnFilterStrs.push(`${belongingToGroupKey}:${groupStr}`);
            }
          });
        } else {
          userGroupStrs.push(`(${belongingToGroupKey} : ${userGroup.groupName}*)`);
        }
      });
      groupName = userGroupStrs?.join(SPLITTER.OR);
    }

    let dataOptionList = { ...bulkImportFilters };
    dataOptionList.start = 0;
    dataOptionList.filterQuery =
      dataOptionList?.filterQuery &&
        dataOptionList?.filterQuery?.includes(`status:'${equipmentStatus?.pending?.value}'`)
        ? dataOptionList?.filterQuery?.replace(`status:'${equipmentStatus?.pending?.value}'`, "")
        : dataOptionList?.filterQuery;
    const itemList = async () => {
      let splitSearchValues = dataOptionList.searchValue ? dataOptionList.searchValue?.split("AND") : [];
      let removeIndex = -1;
      let isActiveAvailable = true;
      if (splitSearchValues?.length > 0) {
        splitSearchValues.forEach((value, index) => {
          if (value?.includes(`status:`)) {
            isActiveAvailable = value?.includes(`Active`) || value?.includes(`*`);
            removeIndex = index;
          }
        });
      }
      if (removeIndex !== -1 && isActiveAvailable) {
        splitSearchValues?.splice(removeIndex, 1);
        splitSearchValues?.push(`(status:'${equipmentStatus?.active?.value}')`);
      } else if (!dataOptionList?.filterQuery && removeIndex === -1) {
        splitSearchValues?.push(`(status:'${equipmentStatus?.active?.value}')`);
      }

      if (splitSearchValues?.length > 0) {
        splitSearchValues = splitSearchValues?.map((value) => value?.trim());
      }

      let joinedAndStr = splitSearchValues?.join(SPLITTER.AND);
      dataOptionList.size = LIMIT_EDIT_FILTER;
      dataOptionList.searchValue = joinedAndStr
        ? `${joinedAndStr} AND (entry_type:${entryType?.standaloneEquipment})`
        : `(entry_type:${entryType?.standaloneEquipment})`;

      if (actionStatus?.hasRole === "User") {
        if (!dataOptionList?.searchValue?.includes(belongingToGroupKey)) {
          dataOptionList.searchValue = groupName
            ? `${dataOptionList.searchValue} AND (${groupName})`
            : `${dataOptionList.searchValue}`;
        } else {
          let replaceStr = "";
          dataOptionList.searchValue?.split(")").forEach((splitValues) => {
            if (splitValues?.includes(belongingToGroupKey)) {
              replaceStr = `${splitValues})`;
            }
          });
          dataOptionList.searchValue = dataOptionList.searchValue?.replace(
            replaceStr,
            `(${groupCloumnFilterStrs?.join(SPLITTER.OR)})`
          );
        }
      }

      try {
        setLoadingEditFilterButton(true);
        if (
          actionStatus.hasRole === "Admin" ||
          (actionStatus.hasRole === "User" && !bulkImportFilters?.searchValue?.includes(belongingToGroupKey)) ||
          (actionStatus.hasRole === "User" && groupCloumnFilterStrs?.length > 0)
        ) {
          if (actionStatus.hasRole === "User") {
            dataOptionList.searchValue = dataOptionList.searchValue.replaceAll(belongingToGroupKey, GROUP_LITERAL);
          }
          if (isActiveAvailable) {
            let resultSearchFilter = await CloudSearchConfig(dataOptionList);
            setSearchFilterCount([...resultSearchFilter?.data]);
          } else {
            setSearchFilterCount([]);
          }
          if (isActiveAvailable) {
            let resultSearchFilter = await CloudSearchConfig(dataOptionList);
            setSearchFilterCount([...resultSearchFilter?.data]);
          } else {
            setSearchFilterCount([]);
          }
        } else {
          setSearchFilterCount([]);
        }
        setLoadingEditFilterButton(false);
      } catch (err) {
        console.log(err);
      }
    };
    itemList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bulkImportFilters]);

  useEffect(() => {
    if (enableCheckbox) {
      if (
        bulkImportFilters !== undefined &&
        bulkImportFilters?.searchValue !== "" &&
        !bulkImportFilters.searchValue.includes("cluster_id")
      ) {
        if (actionStatus.hasRole === "Admin") {
          searchFilterData();
        } else if (actionStatus.hasRole === "User" && groups.length > 0) {
          searchFilterData();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableCheckbox, bulkImportFilters]);

  const handleClick = (event, item) => {
    item?.onClick(event);
  };

  const handleMenuItemClick = (event, key, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleExportActivation = (virtualRowRemovedEvent) => {
    const nodes = virtualRowRemovedEvent?.api?.getRenderedNodes();
    let viewFlag = true;
    let viewFlagDCSV = true;
    for (const node of nodes) {
      const status = node.data?.status?.[0];
      const against = [equipmentStatus?.active?.key, equipmentStatus?.pending?.key];
      if (!against.includes(status)) {
        continue;
      }
      viewFlag = false;
      viewFlagDCSV = status !== equipmentStatus?.active?.key;
      if (!viewFlag && !viewFlagDCSV) {
        break;
      }
    }
    setDownloadBulkCSVDisable(viewFlagDCSV);
    setExportDisable(viewFlag);
  };

  const groupButtonOptions = {
    hideAction: !(actionStatus?.showAddCluster && actionStatus?.showAddEquip),
    actions: [
      {
        key: "add_equipment",
        value: "Add equipment",
        onClick: () => {
          addInstrumentClick(true, false, null);
        }
      },
      {
        key: "add_cluster",
        value: "Add cluster",
        onClick: () => {
          createCluster();
        }
      }
    ],
    defaultLabel: "Add",
    prefixLabel: ""
  };

  const handleDimentionIconClick = (event) => {
    setDimensionAnchor(event.currentTarget);
  };

  const handleDimentionIconClose = () => {
    setDimensionAnchor(null);
    setShowDimensionList(false);
  };

  /** *
   * Get param from export dialog modal and run corresponding export function
   @param {String} type one of ITEMS_LIST_VALUES
   *
   */
  const handleExportType = (type) => {
    setBulkExportStatus(type);
  };

  return (
    <>
      {exportDataModalVisible && (
        <ExportDataDialog
          exportDataModalVisible
          exportModalTitle="Export data"
          setDialogVisible={setExportDataModalVisible}
          actionStatus={actionStatus}
          handleExportType={handleExportType}
          downloadBulkCSVDisable={downloadBulkCSVDisable}
          exportDisable={exportDisable}
        />
      )}

      {openAuditTrial && (
        <AuditTrialDialog
          title="Log entry information"
          openAuditTrial={openAuditTrial}
          handleClose={handleCloseAuditTrial}
          changeItem={auditLogs}
          dataTestId="audit-trial"
          isLoading={loading}
          userLists={userLists}
        />
      )}
      <div
        style={{
          backgroundColor: "var(--one-color-cobas-white)",
          boxShadow: activeModule === SHOW_INSTRUMENT ? "0px 1px 3px rgba(0, 0, 0, 0.4)" : "none",
          paddingTop: activeModule === SHOW_INSTRUMENT ? "16px" : "0px",
          height: activeModule === SHOW_INSTRUMENT ? `${containerHeight + 185}px` : "100%"
        }}
      >
        <ConfirmDialog
          {...dialogProps}
          approveText={titleTextButton}
          approveColor={approveColor}
          approveVariant="contained"
          cancelText="Cancel"
          cancelVariant="outlined"
          cancelColor="primary"
          size="md"
          approveDisable={
            !(
              instrumentToDelete &&
              (isData4eye(instrumentToDelete) ? selectedReason && selectedReviewers.length : selectedReason) &&
              (selectedReason?.trim() !== OTHER_REASON || addComment)
            )
          }
          onApprove={() => {
            instrumentToDelete?.entryType === entryType?.cluster
              ? deleteClusterAction({
                loadInstruments,
                instruments,
                client,
                setInstrumentToDelete,
                selectedReason,
                addComment,
                trackEvent
              })(instrumentToDelete, setDataDelRes)
              : deleteInstrumentAction({
                loadInstruments,
                instruments,
                client,
                setInstrumentToDelete,
                selectedReason,
                addComment,
                selectedReviewers,
                trackEvent
              })(instrumentToDelete, setDataDelRes);
            setAddComment("");
          }}
          onCancel={() => {
            setInstrumentToDelete(null);
            setAddComment("");
            setSelectedReason(null);
            setSelectedReviewers([]);
          }}
          title={<OwcTypography style={{ fontWeight: "bold" }}>{titleText}</OwcTypography>}
          content={
            instrumentToDelete && (
              <>
                <OwcTypography
                  style={{
                    fontSize: 18,
                    marginBottom: 20,
                    color: "#333",
                    fontWeight: "bold"
                  }}
                >
                  {instrumentToDelete?.entryType === entryType?.cluster
                    ? `Are you sure you want to ${contentText} the cluster ?`
                    : `Are you sure you want to ${contentText} the equipment ?`}
                </OwcTypography>
                <div
                  style={{
                    borderTop: "1px solid #C2BAB5",
                    borderBottom: "1px solid #C2BAB5",
                    padding: "16px 0px 16px 4px"
                  }}
                >
                  <ShowEquipmentInfo equipmentDetail={instrumentToDelete} />
                </div>
                <OwcTypography
                  style={{
                    fontSize: 18,
                    margin: "16px 0",
                    color: "#333",
                    fontWeight: "bold"
                  }}
                >
                  {instrumentToDelete?.entryType === entryType?.cluster
                    ? `Reason for ${otherText} cluster`
                    : `Reason for ${otherText} equipment`}
                  {isData4eye(instrumentToDelete) ? (
                    <RefactoredReasonDropdown
                      selectedReason={selectedReason}
                      setSelectedReason={setSelectedReason}
                      addComment={addComment}
                      setAddComment={setAddComment}
                      selectedReviewers={selectedReviewers}
                      setSelectedReviewers={setSelectedReviewers}
                      reviewers={reviewers}
                      isReviewerUpdate={true}
                    />
                  ) : (
                    <ReasonDropdown
                      selectedReason={selectedReason}
                      setSelectedReason={setSelectedReason}
                      addComment={addComment}
                      setAddComment={setAddComment}
                    />
                  )}
                </OwcTypography>
              </>
            )
          }
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 20,
            position: "relative"
          }}
        >
          <OwcTypography variant="title5" style={{ paddingLeft: "16px", display: "flex", margin: "1px" }}>
            Equipment list
          </OwcTypography>
          <span
            onClick={toggleMenu}
            style={{
              position: "absolute",
              right: "6px"
            }}
          >
            {isVisible ? (
              <>
                <OwcIconButton icon="full_screen" type="filled" id="full_screen" flat />
                <OwcTooltip anchor="full_screen" placement="left">
                  Go to fullscreen mode
                </OwcTooltip>
              </>
            ) : (
              <>
                <OwcIconButton icon="full_screen_exit" type="filled" id="exit_full_screen" flat />
                <OwcTooltip anchor="exit_full_screen" placement="left">
                  Exit fullscreen mode
                </OwcTooltip>
              </>
            )}
          </span>
        </div>

        {Object.keys(masterDataFilters)?.length > 0 && (
          <DLabEquipmentList
            autoGroupColumnDefObj={autoGroupColumnDef}
            columnData={dataModel}
            height={containerHeight}
            masterDataFilters={masterDataFilters}
            additionalFields={additionalFields}
            selectedEquipment={selectedEquipmentId}
            userFilters={userFilters}
            myLabFilter={myLabFilter}
            lastFavFilter={InventoryId ? DEFAULT_FILTER : lastFavFilter}
            lastDisplayColumns={lastDisplayColumns ?? DEFAULT_COLUMNS}
            userDisplays={userDisplays}
            systemDisplays={systemDisplays}
            onSave={onSave}
            onVirtualRowRemoved={handleExportActivation}
            onCheckBoxChange={onCheckBoxSelected}
            onChipDelete={onChipDelete}
            defaultFilterObj={DEFAULT_FILTER}
            defaultShowColumns={defaultShowColumns}
            listOfFieldsIgnoredForSearch={listOfFieldsIgnoredForSearch}
            listOfNumberFilter={listOfNumberFilter}
            listOfFieldAcceptOnlyInteger={listOfFieldAcceptOnlyInteger}
            isServerSideGroupObj={isServerSideGroup}
            getServerSideGroupKeyObj={getServerSideGroupKey}
            onResetAll={onResetAll}
            agGridOptions={agGridOptions}
            getCloudSearchOptions={getCloudSearchOptions}
            limit={typeof lastPageSize === "string" ? JSON.parse(lastPageSize)?.ir_grid : lastPageSize?.ir_grid}
            onRowsPerChange={onRowsPerChange}
            requestServer={requestServer}
            dataDelRes={dataDelRes}
            onNavigatePath={onNavigatePath}
            equipmentInventoryId={InventoryId}
            metricValue={units}
            literalFields={literalFields}
            {...attributes}
          >
            <span name="topBarAgGrid">
              <span
                style={{
                  marginLeft: "auto"
                }}
              >
                <div>
                  <OwcIconButton
                    flat
                    onClick={(e) => {
                      setShowDimensionList(!showDimensionList);
                      handleDimentionIconClick(e);
                    }}
                  >
                    <OwcIcon
                      name="ruler"
                      type="outlined"
                      style={{
                        color: dimensionAnchor ? "var(--one-color-interaction-default-brand-1)" : ""
                      }}
                      id="dimensionList"
                    />
                  </OwcIconButton>
                  <OwcTooltip anchor="dimensionList" placement="left">
                    Change Units
                  </OwcTooltip>
                </div>
                {ReactDOM.createPortal(
                  <OwcMenu
                    anchor="dimensionList"
                    visible={dimensionAnchor}
                    onClickOutside={handleDimentionIconClose}
                    style={
                      !showDimensionList
                        ? { display: "none" }
                        : {
                          width: "244px",
                          height: "160px",
                          bordeRadius: "4px",
                          border: "1px",
                          position: "relative"
                        }
                    }
                  >
                    <OwcList expandable expanded header="Select Units">
                      <OwcListItem
                        selected={dimentionUnits.metricUnit === units}
                        onClick={() => {
                          setUnits(dimentionUnits.metricUnit);
                        }}
                      >
                        <OwcRadio>Metric</OwcRadio>
                      </OwcListItem>
                      <OwcListItem
                        onClick={() => {
                          setUnits(dimentionUnits.imperialUnit);
                        }}
                      >
                        <OwcRadio>Imperial</OwcRadio>
                      </OwcListItem>
                    </OwcList>
                  </OwcMenu>,
                  document.body
                )}
              </span>
              <div style={{ marginLeft: "auto" }}>
                <OwcIconButton
                  icon="more_vertical"
                  type="legacy"
                  flat
                  onClick={(event) => {
                    handleSettingClick(event);
                  }}
                  id="csv-menu"
                />
                {ReactDOM.createPortal(
                  <OwcMenu
                    data-testid="simple-menu"
                    anchor="csv-menu"
                    visible={anchorEl}
                    onClickOutside={handleSettingClose}
                    disablePortal={true}
                  >
                    <>
                      <OwcListItem
                        value={3}
                        onClick={() => {
                          setAnchorEl(null);
                          setExportDataModalVisible(true);
                        }}
                      >
                        Export data
                      </OwcListItem>
                      <CoverMenuItem />
                    </>
                    {(actionStatus.hasRole === "Admin" || actionStatus.hasRole === "User") && (
                      <OwcListItem
                        key="Bulk edit"
                        noBorder={true}
                        onClick={() => {
                          setEnableCheckbox(true);
                          setAnchorEl(null);
                        }}
                        value={3}
                      >
                        Bulk edit
                      </OwcListItem>
                    )}
                  </OwcMenu>,
                  document.body
                )}
              </div>
            </span>
            {actionStatus?.showAddCluster && actionStatus?.showAddEquip && (
              <span name="pagination">
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "end",
                    marginRight: enableCheckbox ? 0 : "10px"
                  }}
                >
                  {!enableCheckbox ? (
                    <>
                      <ButtonGroup
                        variant="outlined"
                        size="small"
                        color="primary"
                        ref={anchorRef}
                        aria-label="split button"
                      >
                        <Button
                          style={{
                            textTransform: "none",
                            width: "max-content"
                          }}
                          onClick={(event) => {
                            handleClick(event, groupButtonOptions?.actions[selectedIndex]);
                          }}
                          size="small"
                        >
                          {selectedIndex === null
                            ? groupButtonOptions?.defaultLabel
                            : groupButtonOptions?.prefixLabel + groupButtonOptions?.actions[selectedIndex]?.value}
                        </Button>
                        <Button
                          color="primary"
                          size="small"
                          aria-controls={open ? "split-button-menu" : undefined}
                          aria-expanded={open ? "true" : undefined}
                          aria-label="select merge strategy"
                          aria-haspopup="menu"
                          onClick={handleToggle}
                        >
                          <ArrowDropDown />
                        </Button>
                      </ButtonGroup>
                      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                        {({ TransitionProps, placement }) => (
                          <Grow
                            {...TransitionProps}
                            style={{
                              transformOrigin: placement === "bottom" ? "center top" : "center bottom"
                            }}
                          >
                            <Paper>
                              <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu">
                                  {groupButtonOptions?.actions?.map((option, index) => (
                                    <MenuItem
                                      key={option?.key}
                                      selected={index === selectedIndex}
                                      onClick={(event) => handleMenuItemClick(event, option?.key, index)}
                                    >
                                      {option?.value}
                                    </MenuItem>
                                  ))}
                                </MenuList>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    </>
                  ) : (
                    <>
                      <OwcTypography variant="body2" style={{ marginRight: "10px" }}>
                        Components selected
                      </OwcTypography>
                      <OwcBadge type="active">{agGridOptions?.componentsAdded}</OwcBadge>
                    </>
                  )}
                </div>
              </span>
            )}
          </DLabEquipmentList>
        )}
        {enableCheckbox && (
          <div
            data-testid="bulk-edit-Cover"
            style={{
              display: "flex",
              width: "100%",
              backgroundColor: "var(--one-color-cobas-white)",
              alignItems: "center",
              paddingTop: "15px",
              paddingBottom: "10px"
            }}
          >
            <div style={{ marginLeft: "25px", width: "100%" }}>
              <OwcButton
                data-testid="button-edit-filtered-approve-bulk-edits"
                disabled={
                  loadingEditFilterButton ||
                  bulkImportFilters?.searchValue === "" ||
                  (searchFilterCount !== undefined && searchFilterCount?.length === 0)
                }
                onClick={() => {
                  setActiveModule(BULK_EDIT_ACTIVE);
                  setQueryChoice(SEARCH_FILTER_QUERY);
                }}
                variant="secondary"
              >
                Edit filtered{" "}
                {loadingEditFilterButton === false &&
                  bulkImportFilters?.searchValue !== "" &&
                  searchFilterCount?.length !== 0 &&
                  "(" + searchFilterCount?.length + ")"}
              </OwcButton>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-end"
              }}
            >
              <div style={{ marginRight: "15px", marginLeft: "15px" }}>
                <OwcButton
                  data-testid="confirm-cancel-bulk-edit-button"
                  onClick={() => {
                    setEnableCheckbox(false);
                    setSelectedEquipmentId(() => []);
                  }}
                  variant="secondary"
                >
                  Cancel
                </OwcButton>
              </div>
              <div style={{ marginRight: "25px" }}>
                <OwcButton
                  disabled={selectedEquipmentId.length === 0}
                  data-testid="confirm-bulk-edit-button-approve"
                  onClick={() => {
                    setActiveModule(BULK_EDIT_ACTIVE);
                    setQueryChoice(NORMAL_BULK_EDIT);
                  }}
                  variant="primary"
                >
                  Bulk edit
                </OwcButton>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  editClusterDetail: state?.clusters?.editClusterDetail,
  groupList: state.instruments?.groupList
});

export default compose(
  connect(mapStateToProps, {
    loadInstruments: loadInstrumentsAction,
    updateSearchFilter: updateSearchFilterAction,
    updateClusterDetail: updateClusterDetailAction,
    updateEditClusterDetail: updateEditClusterDetailAction,
    updateLastDisplayColumn: updateLastDisplayColumnAction,
    updateUserDisplayColumns: updateUserDisplayColumnsAction,
    updateLastFavFilter: updateLastFavFilterAction,
    updateUserFilters: updateUserFiltersAction,
    updateLastPageSize: updateLastPageSizeAction
  }),
  withApollo
)(InstrumentsTable);
