import {
  IRGroupAdminMapping,
  IRGroupAdminMappingWithReviewers,
  IRGroupAdminMappingResponse
} from "../../types/dynamoDbTypes";

const hasReviewers = (user: IRGroupAdminMapping): user is IRGroupAdminMappingWithReviewers => !!user.reviewers;

export const extractReviewersIds = (groupsResults: IRGroupAdminMappingResponse[]): string[] => [
  ...new Set(
    groupsResults
      .flatMap((groupsResult) => groupsResult.items)
      .filter(hasReviewers)
      .flatMap((i) => i.reviewers)
      .map((reviewer) => reviewer.userId)
  )
];
